const options = {
  listClass: "feed-list",
  page: per_page,
  pagination: [{
    outerWindow: 1,
  }],
  valueNames: [
    "feed-categories",
    {
      name: "feed-date",
      attr: "datetime",
    },
    "feed-title",
    "feed-tags",
  ],
};

let feed = new List("feed", options);
let checkboxes = document.getElementsByClassName("feed-toggle");
let isChecked = {};
let subToggles = {};
let parentToggle = {};

const updateFeed = (e) => {
  isChecked[e.target.id] = e.target.checked;
  if (e.target.checked && e.target.id in parentToggle) {
    parentToggle[e.target.id].checked = true;
  }
  for (let element of subToggles[e.target.id]) {
    element.checked = e.target.checked;
    isChecked[element.id] = e.target.checked;
  }
  feed.filter((item) => {
    return isChecked[item.values()["feed-categories"]];
  });
};

for (let element of checkboxes) {
  isChecked[element.id] = true;
  subToggles[element.id] = document.getElementsByClassName(`sub-${element.id}`);
  for (let subelement of subToggles[element.id]) {
    parentToggle[subelement.id] = element;
  }
  element.onclick = updateFeed;
}

const toggleVisibility = (toggle, element, display) => {
  if (element.style.display === "none") {
    element.style.display = display;
    toggle.className = "active";
  } else {
    element.style.display = "none";
    toggle.className = "";
  }
}

const filter = document.getElementById("filter");

if (filter) {
  filter.onclick = (e) => {
    toggleVisibility(e.target, document.getElementsByClassName("feed-checkboxes")[0], "grid");
  }

  document.getElementById("tags").onclick = (e) => {
    toggleVisibility(e.target, document.getElementsByClassName("feed-tags-list")[0], "flex");
  }
}

const search = document.getElementsByClassName("search")[0];

for (let element of document.getElementsByClassName("feed-tag")) {
  element.onclick = (e) => {
    search.value = element.innerHTML;
    feed.search(element.innerHTML);
  }
}
